import React, { useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
} from "reactstrap";
import BedModal from "./BedModal";
import { User } from "react-feather";
import BackButton from "../../../components/UI/BackButton";

function AvailableBedSpace() {
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const modals = () => {
    toggle();
  };
  return (
    <div>
      <Modal isOpen={modal} toggle={toggle} size="md">
        <ModalHeader toggle={toggle}>Available Bed Spaces</ModalHeader>
        <ModalBody>
          <BedModal />
        </ModalBody>
        <ModalFooter>
          {/* <Button color="primary" onClick={toggle}>
            Do Something
          </Button>
          {""} */}
        </ModalFooter>
      </Modal>
<BackButton/>
      {/* <Button color="primary">View In-Patient List</Button> */}
      <Card>
        <CardHeader className="text-center">Availabale Bed Space</CardHeader>
        <CardBody>
          <div>PSC Annex</div>
          <Table bordered size="sm" responsive>
            <thead>
              <tr>
                <th>Bed</th>
                <th>Price</th>
                <th>Current Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Medical Ward 1</td>
                <td>22,0000</td>
                <td>0/1 occupied</td>
                <td>
                  <center>
                    <Button color="warning" size="sm" onClick={modals}>
                      <User
                        size={20}
                        fontWeight="bold"
                        style={{ marginRight: 5 }}
                      />{" "}
                      Alocate
                    </Button>
                  </center>
                </td>
              </tr>
            </tbody>
          </Table>

          <div className="mt-2">General Ward</div>
          <Table bordered size="sm" responsive>
            <thead>
              <tr>
                <th>Bed</th>
                <th>Price</th>
                <th>Current Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td> Ward 1</td>
                <td>8,0000</td>
                <td>0/1 occupied</td>
                <td>
                  <center>
                    <Button color="warning" size="sm" onClick={modals}>
                      <User
                        size={20}
                        fontWeight="bold"
                        style={{ marginRight: 5 }}
                      />  
                      Alocate
                    </Button>
                  </center>
                </td>
              </tr>
              <tr>
                <td> Ward 2</td>
                <td>8,0000</td>
                <td>0/1 occupied</td>
                <td>
                  <center>
                    <Button color="warning" size="sm" onClick={modals}>
                      <User
                        size={20}
                        fontWeight="bold"
                        style={{ marginRight: 5 }}
                      />  
                      Alocate
                    </Button>
                  </center>
                </td>
              </tr>
              <tr>
                <td> Ward 3</td>
                <td>8,0000</td>
                <td>0/1 occupied</td>
                <td>
                  <center>
                    <Button color="warning" size="sm" onClick={modals}>
                      <User
                        size={20}
                        fontWeight="bold"
                        style={{ marginRight: 5 }}
                      />  
                      Alocate
                    </Button>
                  </center>
                </td>
              </tr>
            </tbody>
          </Table>

          <div className="mt-2">ICU</div>
          <Table bordered size="sm" responsive>
            <thead>
              <tr>
                <th>Bed</th>
                <th>Price</th>
                <th>Current Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>ICU</td>
                <td>70,0000</td>
                <td>0/1 occupied</td>
                <td>
                  <center>
                    <Button color="warning" size="sm" onClick={modals}>
                      <User
                        size={20}
                        fontWeight="bold"
                        style={{ marginRight: 5 }}
                      />  
                      Alocate
                    </Button>
                  </center>
                </td>
              </tr>
            </tbody>
          </Table>

          <div className="mt-2">Executive Suite</div>
          <Table bordered size="sm" responsive>
            <thead>
              <tr>
                <th>Bed</th>
                <th>Price</th>
                <th>Current Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>PSC 1</td>
                <td>22,0000</td>
                <td>0/1 occupied</td>
                <td>
                  <center>
                    <Button color="warning" size="sm" onClick={modals}>
                      <User
                        size={20}
                        fontWeight="bold"
                        style={{ marginRight: 5 }}
                      />  
                      Alocate
                    </Button>
                  </center>
                </td>
              </tr>
              <tr>
                <td>PSC 2</td>
                <td>22,0000</td>
                <td>0/1 occupied</td>
                <td>
                  <center>
                    <Button color="warning" size="sm" onClick={modals}>
                      <User
                        size={20}
                        fontWeight="bold"
                        style={{ marginRight: 5 }}
                      />  
                      Alocate
                    </Button>
                  </center>
                </td>
              </tr>
            </tbody>
          </Table>
        </CardBody>
      </Card>
    </div>
  );
}

export default AvailableBedSpace;
