import { Outlet } from "react-router-dom"
import LabMenu from "./LabMenu"

function LabIndex() {
    return (
        <div>
          <LabMenu />      
          <Outlet />
    </div>
    )
}

export default LabIndex
