import React from "react";
import { useNavigate } from "react-router";
import './widget-style.css';
import { useSelector } from "react-redux";
import { CardTitle, Col } from "reactstrap";
import { BarChart } from "react-feather";
// import naira from '../../fonts/naira.svg'
// const bg = ['l-bg-blue-dark','l-bg-green-dark', 'l-bg-cherry', 'l-bg-orange', 'l-bg-cyan']
const Widget = ({ fa, link, title, content }) => {

  const { activeBusiness } = useSelector((state) => state.auth);
  const navigate = useNavigate();

  // return (
  //   <div className={`col-xl-3 col-lg-3 col-sm-6 col-s-6`}>
  //     <a href={`#${link}`} onClick={(e) => {
  //       e.preventDefault();
  //       navigate(link)
  //     }}>
  //       <div className='card-widget' style={{ background: activeBusiness?.primary_color }}>
  //         <div className="card-statistic-3 p-4">
  //           <div className="card-icon card-icon-large text-light"><i style={{ fontSize: 50 }} className={`fas ${fa}`}></i></div>
  //           <div className="mb-4">
  //             <h3 className="card-title mb-0  text-white text-left">{title}</h3>
  //           </div>
  //           <div className="row align-items-center mb-2 d-flex">
  //             <div className="col-12">
  //               <h5 className="text-white mb-0 text-right">
  //                 {content} <i className="text-xl text-white fa fa-chevron-circle-right"></i>
  //               </h5>
  //             </div>
  //             {/* <div className="col-3 col-md-3 offset-8 text-right"
  // 							style={{ opacity: .8 }} >
  // 						</div> */}
  //           </div>
  //         </div>
  //       </div>
  //     </a>
  //   </div>
  // )
  return (
    <Col className="shadow p-4 mx-2 mb-2 round text-center bg-primary" style={{  color: '#FFFFFF' }}>
      <a href={`#${link}`} style={{ textDecoration: 'none', color: '#FFF' }} onClick={(e) => {
        e.preventDefault();
        navigate(link)
      }}><div>
          <CardTitle
            tag="h5"
            className="text-uppercase text-light mb-0"
          >
            {content}
          </CardTitle>
          <span style={{ opacity: .8 }} className="h5 font-weight-bold mb-0">
            <BarChart color="#FFF" /> {title}
          </span>
        </div>
      </a>
    </Col>)
}

export default Widget;