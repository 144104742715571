import React, { useCallback, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./nav.css";
import {
  Collapse,
  NavbarBrand,
  Navbar,
  NavbarToggler,
  List,
  Col,
} from "reactstrap";
import CustomButton from "../UI/CustomButton";
import logo from "../../assets/images/white-logo.png";
import NavItems from "./NavItems";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../redux/action/auth";
import { getExpiryAlertTopFive } from "../../redux/action/pharmacy";
import moment from "moment";
import { today } from "../UI/helpers";
import Marquee from "react-fast-marquee";

const MainNavbar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const _logout = () => dispatch(logout(gotoLogin()));
  const gotoLogin = () => navigate("/app/login");
  return (
    <div>
      <AlertNews />
      <Navbar
        className="navbar-main mb-1 py-0 bg-primary"
        expand="md"
        // fixed=""
        position="absolute"
        style={{ maxHeight: 50 }}
      >
        <NavbarBrand
          className="mr-lg-5 "
          to="/app/pharmacy/dashboard"
          tag={Link}
        >
          <div>
            <img alt="logo" src={logo} height="40" />
          </div>
        </NavbarBrand>
        <div className="text-center">
          <h1 className="text-white text-center  ml-lg-5">{""}</h1>
        </div>
        <NavbarToggler onClick={() => {}} />
        <Collapse
          toggler="#navbar_global"
          navbar
          className={"d-flex flex-direction-row justify-content-between"}
        >
          <NavItems />
        </Collapse>
        <div className="">
          <CustomButton className="" color="danger" onClick={_logout}>
            Logout
          </CustomButton>
        </div>
      </Navbar>
    </div>
  );
};

const AlertNews = () => {
  const dispatch = useDispatch();
  const { topFive } = useSelector((state) => state.pharmacy);
  const info = useCallback(() => {
    dispatch(getExpiryAlertTopFive());
  }, [dispatch]);
  useEffect(() => {
    info();
  }, [info]);
  return (
    <Marquee className="bg-danger">
      {" "}
      {topFive.map((state) => (
        <>
          {state.expiry_date === "1111-11-11" ? (
            ""
          ) : (
            <ul className="d-inline-flex m-0 font-weight-italic text-white" >
             <li> {state.drug_name}
              (Expires in {moment(state.expiry_date).diff(
                today,
                "days"
              )} days)</li> 
            </ul>
          )}
        </>
      ))}
    </Marquee>
  );
};

export default MainNavbar;
