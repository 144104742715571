import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useQuery } from "../../hooks";
// import { Nav, NavItem } from "reactstrap";
import HorizontalMenu from '../UI/horizontal-menu/HorizontalMenu'
import HorizontalMenuItem from '../UI/horizontal-menu/HorizontalMenuItem'
export const routes = [
  {
    label: "Dashboard", path: "dashboard?type=Dashboard",
  },
  {
    label: "Sales",
    path: "drug-sales?type=Sales",
    // icon: <BarChart2 size={16} />,
  },
  {
    label: "Inventory",
    path: "drug-purchase/drug-purchase?type=Inventory&tab=Drug shelve",
    // icon: <Square size={16} />,
  },
  {
    label: "Expenses",
    path: "expenses"
  },
  {
    label: "Suppliers",
    path: "manage-suppliers?type=Suppliers",
    // icon: <Truck size={16} />,
  },

  {
    label: "Clients Manager",
    path: "manage-customer?type=Clients Manager",
    // icon: <UserPlus size={16} />,
  },
  {
    label: "Generate Reciept",
    path: "generate-reciept?type=Generate Reciept",
    // icon: <Book size={16} />,
  },
  // { label: "My Sales", path: "sales-report?type=", icon: <FilePlus size={16} /> },
  // { label: "Description", path: "description?type=", icon: <FileText size={16} /> },
  // { label: "Orders", path: "order?type=", icon: <ShoppingCart size={16} /> },
  {
    label: "Store Setup",
    path: `store-setup?type=Store Setup&name=Agents`,
  },
  {
    label: "Reports",
    path: `reports?type=Reports`,
  },
  // {
  //   label: "Product Category",
  //   path: "product-category?type=",
  //   icon: <Image size={16} />,
  // },
  // {
  //   label: "Transfer Form",
  //   path: "transfer-form?type=",
  //   icon: <Image size={16} />,
  // },
];

function NavItems() {
  // const navigate = useNavigate();
  // const { user } = useSelector(s => s.auth)
  //   return (
  //     <>
  //       <Nav className="mr-auto justify-content-between" navbar>
  //         {/* {JSON.stringify(user)} */}
  //         {routes.map((item) => (
  //           <NavItem className="">
  //             <NavLink
  //               to={item.path}
  //               className="nav"
  //               style={{ fontSize: 13, fontWeight: "italic" }}
  //             >
  //               {item.label}
  //             </NavLink>
  //           </NavItem>
  //         ))}
  //       </Nav>
  //     </>
  //   );
  // }

  const accessTo = useSelector((state) => state.auth.user.accessTo);
  const _accessTo = (accessTo && accessTo.split(",")) || [];

  return (
    <HorizontalMenu className="">
      {/* {JSON.stringify(_accessTo)} */}
      {routes.map((item, index) => (
        <HorizontalMenuItem
          key={index}
          route={`/app/pharmacy/${item.path}`}
          label={item.label}
        >
          {(_accessTo.includes(item.label, item.icon) || item.label === 'Dashboard') ? (
            <span>
              <span style={{ margin: "3px" }}>{item.icon}</span>
              {item.label}
            </span>

          ) : null}
        </HorizontalMenuItem>
      ))}
    </HorizontalMenu>
  );
}
export default NavItems;
