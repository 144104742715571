import React, { useState } from "react";
import { Trash } from "react-feather";
import { useSelector } from "react-redux";
import { Col, Row, Table } from "reactstrap";
import { CustomButton, CustomForm } from "../../../components/UI";
import CustomCard from "../../../components/UI/CustomCard";
import CustomTypeahead from "../../../components/UI/CustomTypeahead";
import { formatNumber } from "../../../components/UI/helpers";

function DrugCardList({
  list = [],
  setSelectedCustomer = {},
  otherInfo = {},
  handleOtherInfoChange = (f) => f,
  handleSubmit = (f) => f,
  amountPaidRef,
  handleDelete = (f) => f,
  clientInfo = [],
  setForm = (f) => f,
  defaulCustomer = [],
}) {
  const _discount = useSelector((state) => state.pharmacy.discount);
  const { activeBusiness } = useSelector((state) => state.auth);
  const [customer, setCustomer] = useState({});
  let total = list.reduce((a, b) => a + parseFloat(b.amount), 0);
  let checkout = `Submit ₦ ${formatNumber(
    otherInfo.amountPaid > 0 ? otherInfo.amountPaid : total
  )}`;
  let change = parseFloat(otherInfo.amountPaid) - total;
  if (
    otherInfo.amountPaid === null ||
    otherInfo.amountPaid === 0 ||
    (otherInfo.amountPaid > 0 && otherInfo.amountPaid !== total)
  ) {
    otherInfo.amountPaidDefault =
      otherInfo.amountPaid > 0 ? otherInfo.amountPaid : total;
    otherInfo.total = total;
  }
  const fields = [
    {
      type: "number",
      label: "Amount Paid ",
      name: "amountPaid",
      placeholder: formatNumber(total),
      value: otherInfo.amountPaid,
      size: "sm",
      col: 6,
    },
    {
      type: "select",
      options: ["Fixed", "Percentage"],
      label: "Discount type",
      name: "discount_type",
      value: otherInfo.discount_type,
      size: "sm",
      col: 6,
    },
    {
      type: "number",
      label: `Discount(${formatNumber(parseFloat(_discount)) || 0})`,
      name: "discount_value",
      value: otherInfo.discount_value,
      onFocus: (e) => e.target.select(),
      size: "sm",
      col: 6,
    },
    {
      type: "select",
      label: "Mode of Payment",
      name: "modeOfPayment",
      size: "sm",
      options: Object.values({
        CASH: "CASH",
        POST: "POS",
        BANK_TRANSFER: "BANK TRANSFER",
        CREDIT: "CREDIT",
        CHEQUE: "CHEQUE",
      }),
      col: 6,
      value: otherInfo.modeOfPayment,
    },
  ];
  return (
    <CustomCard container="p-0 p-100" header="Cart List">
      <CustomTypeahead
        placeholder="Select  Customer"
        labelKey={(item) => `${item.accName}(${item.accountNo})`}
        defaultSelected={defaulCustomer}
        options={clientInfo.filter(
          (state) => state.accountNo !== `${activeBusiness.prefix}-1`
        )}
        onChange={(s) => {
          if (s.length) {
            console.log(s);
            setSelectedCustomer(s[0]);
            setCustomer(s[0]);
            setForm((p) => ({
              ...p,
              acct: s[0].accountNo,
              name: s[0].accName,
              balance: s[0].balance,
            }));
          }
        }}
      />
      <div style={{ height: "75vh" }}>
        {customer.accName && customer.accName !== "walk-in" ? (
          <Row>
            <Col>
              Balance{" "}
              {customer.balance ? "₦" + formatNumber(customer.balance) : 0}
            </Col>
            <Col className="text-end">
              Credit limit:{" "}
              {customer.credit_limit
                ? "₦" + formatNumber(customer.credit_limit)
                : 0}
            </Col>
          </Row>
        ) : (
          ""
        )}

        <div className="text-end">Total Amount: ₦{formatNumber(total)}</div>
        <div style={{ overflow: "scroll" }} className="h-50">
          <Table size="sm">
            <thead>
              <tr>
                <th className="text-center">Drug Name</th>
                <th className="text-center">Qty</th>
                <th className="text-center">Price</th>
                <th className="text-center">Total</th>
                <th className="text-center">X</th>
              </tr>
            </thead>
            <tbody>
              {list.map((item, index) => (
                <tr key={index}>
                  <td>{item.item_name}</td>
                  <td className="text-center">{formatNumber(item.quantity)}</td>
                  <td className="text-end">{formatNumber(item.price)}</td>
                  <td className="text-end">
                    {formatNumber(
                      parseInt(item.price) * parseInt(item.quantity)
                    )}
                  </td>
                  <td
                    className="text-center"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      handleDelete(index);
                    }}
                  >
                    <Trash className="text-danger" size={20} />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
        <Row className="h-30">
          <CustomForm fields={fields} handleChange={handleOtherInfoChange} />
        </Row>
        <center>
          {parseFloat(customer.balance) +
            parseFloat(customer.credit_limit || 0) <
          total ? (
            <Col className="text-danger">
              <strong>Warning: </strong>
              {`₦${formatNumber(
                total
              )} exceeded a credit limit of (₦${formatNumber(
                parseFloat(customer.credit_limit)
              )}).`}
            </Col>
          ) : (
            ""
          )}
          <CustomButton
            disabled={
              total <= 0 ||
              parseFloat(customer.balance) +
                parseFloat(customer.credit_limit || 0) <
                total
            }
            onClick={(e) => {
              handleSubmit(e);
            }}
            className="px-5"
          >
            {checkout}
          </CustomButton>
        </center>
      </div>
    </CustomCard>
  );
}

export default DrugCardList;
