import moment from "moment";
import React, { useCallback } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Row,
  Card,
  CardBody,
  Table,
  // CardTitle,
  Col,
  // CardHeader,
  // Input,
} from "reactstrap";
import { Checkbox, SearchBar } from "../../components/UI";
import CustomScrollbar from "../../components/UI/CustomScrollbar";
import DaterangeSelector from "../../components/UI/DaterangeSelector";
import { formatNumber } from "../../components/UI/helpers";
// import Loading from "../../components/UI/Loading";
import Widget from "../../components/UI/Widget";
import { getAllReport } from "../../redux/action/pharmacy";
import DashboardSales from "./DashboardSales";

function PharmacyDashboard() {
  const today = moment().format("YYYY-MM-DD");
  const [dateInfo, setDateInfo] = useState({ from: today, to: today });
  const dispatch = useDispatch();
  const [purchase, setPurchase] = useState([]);
  const [sales, setSales] = useState([]);
  const [discount, setDiscount] = useState([]);
  const [debts, setDebts] = useState([]);
  const [list, setList] = useState([]);
  const [items, setItems] = useState([]);
  const user = useSelector((state) => state.auth.user);
  const [showAllPurchase, setShowAllPurchase] = useState(false);
  const { activeBusiness } = useSelector((state) => state.auth);
  const syncData = useCallback(() => {
    dispatch(
      getAllReport(setPurchase, {
        from: dateInfo.from,
        to: dateInfo.to,
        query_type: "Purchase summary",
      })
    );
    dispatch(
      getAllReport(setSales, {
        from: dateInfo.from,
        to: dateInfo.to,
        query_type: "Sales summary",
      })
    );
    dispatch(
      getAllReport(setDiscount, {
        from: dateInfo.from,
        to: dateInfo.to,
        query_type: "Discount summary",
      })
    );
    dispatch(
      getAllReport(setDebts, {
        from: dateInfo.from,
        to: dateInfo.to,
        query_type: "Debt summary",
      })
    );
  }, [dispatch, dateInfo.from, dateInfo.to]);

  useEffect(() => {
    syncData();
  }, [syncData]);

  const getReports = useCallback(() => {
    dispatch(
      getAllReport(setList, {
        from: dateInfo.from,
        to: dateInfo.to,
        query_type: "Purchase category summary",
      })
    );
  }, [dispatch, dateInfo.from, dateInfo.to]);
  const [searchTxt, addSearchTxt] = useState("");

  const retrieveList = useCallback(() => {
    setItems(
      searchTxt.length > 2 && list.length
        ? list.filter((item) => {
            return item.description
              ?.toLowerCase()
              ?.includes(
                searchTxt.toLowerCase() ||
                  item.receive_date.toString().includes(searchTxt)
              );
          })
        : list
    );
    //  setItems(list);
  }, [list, searchTxt]);
  useEffect(() => {
    getReports();
  }, [getReports]);

  useEffect(() => {
    retrieveList();
  }, [retrieveList]);
  const fetchData = useCallback(() => {
    // dispatch(getItemList());
  }, [dispatch]);
  const handleChange = ({ target: { name, value } }) => {
    setDateInfo((p) => ({ ...p, [name]: value }));
  };
  const totalAmount = list.reduce(
    (a, b) => parseFloat(a) + parseFloat(b.amount),
    0
  );
  const total_selling_price = list.reduce(
    (a, b) => parseFloat(a) + parseFloat(b.selling_price) * parseFloat(b.qty),
    0
  );
  const final = items.length > 0 && showAllPurchase ? items : items.slice(-15);
  return (
    <Card
      style={{
        borderWidth: 1,
        borderColor: activeBusiness?.primary_color,
        borderStyle: "solid",
        // zIndex: -999,
      }}
    >
      <CardBody
        style={{
          height: "90vh",
        }}
      >
        <div className="">
          <span className="h5">Welcome back, {activeBusiness.pharmacy_name}</span>
        </div>
        {user.role === "Pharmacy Owner" ? (
          <DaterangeSelector
            handleChange={handleChange}
            from={dateInfo.from}
            to={dateInfo.to}
          />
        ) : (
          <hr />
        )}
        <Row>
          {user.role === "Pharmacy Owner" ? (
            <Widget
              fa="fa-store"
              link={`/app/pharmacy/reports?type=Dashboard&_type=Purchase category summary&from=${dateInfo.from}&to=${dateInfo.to}`}
              title="Total purchase"
              content={`₦ ${
                purchase.length
                  ? purchase[0].total
                    ? formatNumber(purchase[0].total)
                    : 0
                  : 0
              }`}
            />
          ) : (
            ""
          )}
          <Widget
            fa="fa-credit-card"
            link={`/app/pharmacy/reports?type=Dashboard&_type=Sales category summary&from=${dateInfo.from}&to=${dateInfo.to}`}
            title="Total sales"
            content={`₦ ${
              sales.length
                ? sales[0].total
                  ? formatNumber(sales[0].total)
                  : 0
                : 0
            }`}
          />
          <Widget
            fa="fa-briefcase"
            link={`/app/pharmacy/reports?type=Dashboard&_type=Discount category summary&from=${dateInfo.from}&to=${dateInfo.to}`}
            title="Total discount"
            content={`₦ ${
              discount.length
                ? discount[0].total
                  ? formatNumber(discount[0].total)
                  : 0
                : 0
            }`}
          />
          {user.role === "Pharmacy Owner" ? (
            <Widget
              fa="fa-briefcase"
              link={`/app/pharmacy/reports?type=Dashboard&_type=Debt category summary&from=${dateInfo.from}&to=${dateInfo.to}`}
              title="Total debts"
              content={`₦ ${
                debts.length
                  ? debts[0].total
                    ? formatNumber(debts[0].total)
                    : 0
                  : 0
              }`}
            />
          ) : (
            ""
          )}
        </Row>
        {user.role === "Pharmacy Owner" ? (
          <>
            {" "}
            <Row>
              <Col md={10}>
                <SearchBar
                  onFilterTextChange={(v) => addSearchTxt(v)}
                  filterText={searchTxt}
                  placeholder="search for purchase"
                />
              </Col>
              <Col className="d-flex flex-direction-row align-items-center">
                <Checkbox
                  label="Show All"
                  checked={showAllPurchase}
                  onChange={() => setShowAllPurchase((p) => !p)}
                />
                {/* <CustomButton
              className="mb-2 btn-block"
              onClick={() => {
                // retrieveList()
                syncData()
                fetchData()
              }}
            >
              Get list
            </CustomButton> */}
              </Col>
            </Row>
            <div
              className=""
              style={{
                marginLeft: "auto",
                marginRight: 0,
                paddingRight: "20px",
              }}
            >
              <div style={{ fontSize: "12px", fontFamily: "sans-serif" }}>
                Total No. of Items:{" "}
                <span style={{ textAlign: "center", fontWeight: "bold" }}>
                  {final.length}
                </span>
              </div>
              <div style={{ fontSize: "12px", fontFamily: "sans-serif" }}>
                Total Cost:
                <span style={{ textAlign: "center", fontWeight: "bold" }}>
                  ₦{formatNumber(totalAmount)}
                </span>
              </div>
              <div style={{ fontSize: "12px", fontFamily: "sans-serif" }}>
                Total Selling Price:
                <span style={{ textAlign: "center", fontWeight: "bold" }}>
                  ₦{formatNumber(total_selling_price)}
                </span>
              </div>
            </div>
          </>
        ) : null}
        <CustomScrollbar height="38vh">
          {user.role === "Pharmacy Owner" ? (
            <Table bordered>
              <thead>
                <tr>
                  <th className="text-center">S/N</th>
                  <th className="text-center">Date</th>
                  <th className="text-center">Drug Name </th>
                  <th className="text-center">Quantity</th>
                  <th className="text-center">Cost Price ({"₦"})</th>
                  <th className="text-center">Amount ({"₦"})</th>
                </tr>
              </thead>

              <tbody>
                {final.map((item, i) => (
                  <tr key={i}>
                    <th scope="row">{i + 1}</th>
                    <td>{item.receive_date}</td>
                    <td>{item.description}</td>

                    <td className="text-center">{formatNumber(item.qty)}</td>
                    <td className="text-end">
                      {formatNumber(item.unit_price)}
                    </td>
                    <td className="text-end">{formatNumber(item.amount)}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <DashboardSales />
          )}
        </CustomScrollbar>
      </CardBody>
    </Card>
  );
}

export default PharmacyDashboard;
