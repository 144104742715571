import React from "react";
import { useNavigate } from "react-router-dom";
import { Col, Row } from "reactstrap";
import { CustomButton } from "../../../components/UI";
import CustomCard from "../../../components/UI/CustomCard";
import { useQuery } from "../../../hooks";
import ItemsList from "./ItemList";
import SalesForm from "./SaleForm";

function SelectedDrugList({
  disabled,
  form = {
    filterText: "",
  },
  hide = false,
  itemList = {},
  addToCart = (f) => f,
  selectItem = (f) => f,
  qttyRef,
  itemNameRef,
  handleChange,
  handleSubmit = (f) => f,
  filterText,
  setFilterText,
  options,
  user_id,
}) {
  const navigate = useNavigate();
  const query = useQuery();
  const type = query.get("type");
  return (
    <CustomCard
      back
      container="p-0 h-100"
      body="p-2"
      header="Drug shelve"
      headerRight={
        <Row>
          {/* <Col><CustomButton size='sm' outline onClick={() => navigate('/app/pharmacy/returned-drugs?type=return')}>Clients</CustomButton></Col> */}
          <Col>
            <CustomButton
              size="sm"
              outline
              onClick={() =>
                navigate(
                  `/app/pharmacy/returned-drugs?type=${type}&type=return`
                )
              }
            >
              Returned drugs
            </CustomButton>
          </Col>
        </Row>
      }
    >
      <>
        <SalesForm
          options={options}
          form={form}
          qttyRef={qttyRef}
          itemNameRef={itemNameRef}
          handleChange={handleChange}
          filterText={filterText}
          setFilterText={setFilterText}
          disabled={disabled}
          user_id={user_id}
        />
        {/* <div className="h-75"> */}
        <ItemsList
          filterText={filterText}
          selectItem={selectItem}
          list={itemList}
        />
        {/* </div> */}
        <div className="mt-2">
          <CustomButton color="primary" className="btn " outline>
            Press F10 to submit
          </CustomButton>
          <CustomButton
            color="primary"
            className="float-end"
            onClick={addToCart}
          >
            Press Enter to Add To Cart
          </CustomButton>
        </div>
      </>
    </CustomCard>
  );
}

export default SelectedDrugList;
