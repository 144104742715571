import React from "react";
import { Col, Row } from "reactstrap";
import { formatNumber } from "../../../components/UI/helpers";
import Scrollbar from "../../../components/UI/Scrollbar";
import ItemAvatar from "./ItemAvatar";

function ItemsList({ list = [], selectItem = (f) => f, filterText = "" }) {
  const rows = [];

  list
    .filter((i) => i.balance > 0)
    .forEach((item) => {
      if (
        item.drug_name &&
        item.drug_name.toLowerCase().indexOf(filterText.toLowerCase()) === -1
      )
        return;

      rows.push(item);
    });

  return (
    <div>
      <Scrollbar style={{ height: "50vh" }} >
        <Row >
          {list.map((item, index) => (
            <Col md={3} className="m-0 p-1">
              <Item index={index} item={item} selectItem={selectItem} />
            </Col>
          ))}
        </Row>
      </Scrollbar>
    </div>
  );
}

function Item({ item = {}, selectItem = (f) => f,index }) {
  return (
    <>
      <div
        className={`card card-body  h-100 shadow ${item.selected?"border border-primary":''} `}
        style={{ cursor: "pointer" }}
        onClick={() => selectItem(item,index)}
      >
        <div className="p-1 border border-bottom-primary">
          <ItemAvatar item={item} value={item.drug_name} />
        </div>
        <div className="p-1">
          <div className="font-weight-bold text-center">
            <span>{item.drug_name}</span>
            <span>{item.drug_category}</span>
            <span>{item.generic_name}</span>
            <span>{item.uom}</span>
          </div>
          <div className="font-weight-bold text-center">
            ₦ {formatNumber(item.selling_price)}
          </div>
          <div className="text-center font-italic">
            {item.balance} {item.uom} available
          </div>
        </div>
      </div>
    </>
  );
}

export default ItemsList;
