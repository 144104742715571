// Pharmacy Action Type

export const GET_SUPPLIER_INFO = "GET_SUPPLIER_INFO";
export const GET_SUPPLIER_STATEMENT = "GET_SUPPLIER_STATEMENT";
export const GET_DRUG_LIST = "GET_DRUG_LIST";
export const GET_DRUG_LIST_COUNT ="GET_DRUG_LIST_COUNT";
export const GET_PURCHASE_ITEM = "GET_PURCHASE_ITEM";
export const GET_PHARM_STORE = "GET_PHARM_STORE";
export const PHARM_LOADING = "PHARM_LOADING";
export const GET_CLIENT_INFO = "GET_CLIENT_INFO";
export const NUMBER_OF_OUT_OF_STOCK ="NUMBER_OF_OUT_OF_STOCK";
export const TOTAL_EXPIRED_DRUGS ="TOTAL_EXPIRED_DRUGS"
export const GET_CLIENT_STATEMENT = "GET_CLIENT_STATEMENT";
export const SALES = "SALES";
export const PHARM_USER = "PHARM_USER";
export const RECEIPT_DATA = "RECEIPT_DATA";
export const SUPPLIER_COUNT = 'SUPPLIER_COUNT';
export const GET_STOCK_INFO = "GET_STOCK_INFO";
export const GET_STOCK_INFO_STORE = 'GET_STOCK_INFO_STORE';
export const GET_STOCK_INFO_STORE_SHELF = 'GET_STOCK_INFO_STORE_SHELF';
export const GET_TOP_SALES = 'GET_TOP_SALES';
export const GET_REORDER_LEVEL = 'GET_REORDER_LEVEL';
export const GET_EXPIRY_ALERT = 'GET_EXPIRY_ALERT';
export const GET_TRANSACTIONS = 'GET_TRANSACTIONS';
export const REPORT_LIST = "REPORT_LIST";
export const DISPATCH_AMOUNT_PAID = "DISPATCH_AMOUNT_PAID";
export const DISPATCH_DISCOUNT = "DISPATCH_DISCOUNT"
export const EXPIRED_STOCKS = 'EXPIRED_ITEMS'
export const NA_STOCKS = 'NotAvailable_STOCK-ITEMS'
export const SELECTED_USER = 'SELECTED_USER'
export const ACTIVEBUSINESS='ACTIVEBUSINESS'
export const GET_PURCHASE = "GET_PURCHASE"
export const VIEW_PURCHASE = "VIEW_PURCHASE"
export const GET_DISCOUNT ='GET_DISCOUNT'
export const GET_EXPIRY_ALERT_TOP_FIVE="GET_EXPIRY_ALERT_TOP_FIVE"

export const GET_TOTAL_DRUG = 'GET_TOTAL_DRUG'
// user Action Type 

export const LOADING = "LOADING";
export const LOGIN = "LOGIN";
export const ERROR = "ERROR";
export const LOGOUT = "LOGOUT";
export const CREATING_USER = "CREATING_USER";
export const ERROR_CREATING_USER = "ERROR_CREATING_USER";
export const LOGGING_IN = "LOGGING_IN";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const SET_PROFILE = "SET_PROFILE";
export const RESTORE_TOKEN = "RESTORE_TOKEN";
export const SIGN_UP_LOADING = "SIGN_UP_LOADING";
export const SET_APP_THEME = "SET_APP_THEME";
export const SET_ACTIVE_BUSINESS = "SET_ACTIVE_BUSINESS";
export const SET_FORM_SETUP = "SET_FORM_SETUP";

// account action type
export const GETTING_SUMMARY_REPORT = "GETTING_SUMMARY_REPORT";
export const GET_SUMMARY_REPORT = "GET_SUMMARY_REPORT";
export const GET_ALL_PENDING_REQ = "GET_ALL_PENDING_REQ";
export const GET_BRANCH_REQ = "GET_BRANCH_REQ";
export const GET_REQUISITION_LIST = "GET_REQUISITION_LIST";
export const GETTING_ACC_CHART = "GETTING_ACC_CHART";
export const GET_ACC_CHART_TREE = "GET_ACC_CHART_TREE";
export const GET_ITEM_CHART_TREE = "GET_ITEM_CHART_TREE";
export const GET_ACC_CHART = "GET_ACC_CHART";
export const GET_OTHER_EXPENSES = "GET_OTHER_EXPENSES";
export const GET_SALES = "GET_SALES";
export const GET_SALES2 = "GET_SALES2";

// transactions action type
export const CREATE_ACC_HEAD = "CREATE_ACC_HEAD"
export const CREATE_ACC_HEAD_LOADING = "CREATE_ACC_HEAD_LOADING"
export const GET_EXPENDITURE_ACC_REPORT = "GET_EXPENDITURE_ACC_REPORT"
export const GET_ACC_HEAD = "GET_ACC_HEAD"
export const GET_REV_ACC_HEAD = "GET_REV_ACC_HEAD"
export const GETTING_TOTAL_SALES_BY_USER = "GETTING_TOTAL_SALES_BY_USER"
export const GET_TOTAL_SALES_BY_USER = "GET_TOTAL_SALES_BY_USER"
export const GET_AMOUNT_RECEIVED = "GET_AMOUNT_RECEIVED"
export const GET_AMOUNT_HANDEDOVER = "GET_AMOUNT_HANDEDOVER"
export const GET_EXPENSES_ACC_HEAD = "GET_EXPENSES_ACC_HEAD"
export const GET_GENERAL_ACC_REPORT_LOADING = "GET_GENERAL_ACC_REPORT_LOADING"
export const GET_GENERAL_ACC_REPORT = "GET_GENERAL_ACC_REPORT"
export const GET_REVENUE_ACC_REPORT = "GET_REVENUE_ACC_REPORT"
export const GET_ITEM_HEAD = "GET_ITEM_HEAD"
export const NEW_ITEM_DESCRIPTION = "NEW_ITEM_DESCRIPTION"
//   export const GETTING_ACC_CHART="GETTING_ACC_CHART"
//   export const GET_ITEM_CHART_TREE="GET_ITEM_CHART_TREE"