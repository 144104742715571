import react from "react";
import { Button, Card, CardBody, CardHeader, Table } from "reactstrap";
import AddPatientButton from "./AddNewPatient";

function AllPatientList() {
  return (
    <div>
        <AddPatientButton />
      <Card>
        <CardHeader>All Patient List</CardHeader>
        <CardBody>
          <Table bordered size="sm" responsive>
            <thead>
              <tr>
                <th>Id</th>
                <th>Full Name</th>
                <th>Address</th>
                <th>Edit</th>
                <th>Assign</th>
                <th>Waiting List</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>349428</td>
                <td>Alh. Maggi Yaro</td>
                <td>Kantin Kwari</td>
                <td>
                  <Button color="primary" size="sm">
                    Edit
                  </Button>
                </td>
                <td>
                  <Button color="success" size="sm">
                    Assign
                  </Button>
                </td>
                <td>
                  <Button color="warning" size="sm">
                    Assign to Waiting List
                  </Button>
                </td>
              </tr>
              <tr>
                <td>349428</td>
                <td>Alh. Maggi Yaro</td>
                <td>Kantin Kwari</td>
                <td>
                  <Button color="primary" size="sm">
                    Edit
                  </Button>
                </td>
                <td>
                  <Button color="success" size="sm">
                    Assign
                  </Button>
                </td>
                <td>
                  <Button color="warning" size="sm">
                    Assign to Waiting List
                  </Button>
                </td>
              </tr>
              <tr>
                <td>349428</td>
                <td>Alh. Maggi Yaro</td>
                <td>Kantin Kwari</td>
                <td>
                  <Button color="primary" size="sm">
                    Edit
                  </Button>
                </td>
                <td>
                  <Button color="success" size="sm">
                    Assign
                  </Button>
                </td>
                <td>
                  <Button color="warning" size="sm">
                    Assign to Waiting List
                  </Button>
                </td>
              </tr>
              <tr>
                <td>349428</td>
                <td>Alh. Maggi Yaro</td>
                <td>Kantin Kwari</td>
                <td>
                  <Button color="primary" size="sm">
                    Edit
                  </Button>
                </td>
                <td>
                  <Button color="success" size="sm">
                    Assign
                  </Button>
                </td>
                <td>
                  <Button color="warning" size="sm">
                    Assign to Waiting List
                  </Button>
                </td>
              </tr>
              <tr>
                <td>349428</td>
                <td>Alh. Maggi Yaro</td>
                <td>Kantin Kwari</td>
                <td>
                  <Button color="primary" size="sm">
                    Edit
                  </Button>
                </td>
                <td>
                  <Button color="success" size="sm">
                    Assign
                  </Button>
                </td>
                <td>
                  <Button color="warning" size="sm">
                    Assign to Waiting List
                  </Button>
                </td>
              </tr>
              <tr>
                <td>349428</td>
                <td>Alh. Maggi Yaro</td>
                <td>Kantin Kwari</td>
                <td>
                  <Button color="primary" size="sm">
                    Edit
                  </Button>
                </td>
                <td>
                  <Button color="success" size="sm">
                    Assign
                  </Button>
                </td>
                <td>
                  <Button color="warning" size="sm">
                    Assign to Waiting List
                  </Button>
                </td>
              </tr>
            </tbody>
          </Table>
        </CardBody>
      </Card>
    </div>
  );
}

export default AllPatientList;
